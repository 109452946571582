import React from "react";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
function Footer() {
  return (
    <footer className="text-center bg-gray-900 mt-32">
      <div className="container mx-auto px-6 pt-6">
        <div className="flex justify-center mb-6">
          <a href="https://github.com/stevenballah" target="_blank" rel="noreferrer" className="pl-1.5 pt-1.5 rounded-full m-1 text-white border-2 h-9 w-9">
            <FaGithub size="20px" />
          </a>
          {/* <a href="https://www.instagram.com/steven_ballah/" target="_blank" rel="noreferrer" className="pl-1.5 pt-1.5 rounded-full m-1 text-white border-2 h-9 w-9">
            <FaInstagram size="20px" />
          </a>
          <a href="https://www.linkedin.com/in/steven-ballah-203863138/" target="_blank" rel="noreferrer" className="pl-1.5 pt-1.5 rounded-full m-1 text-white border-2 h-9 w-9">
            <FaLinkedin size="20px" />
          </a> */}
        </div>

        <div className="flex justify-center py-4">
            <p className="text-white text-sm font-medium">© {new Date().getFullYear()} Developed by Steven Ballah</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
