import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import Footer from "./components/includes/Footer";
import Navbar from "./components/includes/Navbar";
import Contact from "./components/pages/Contact";
import Projects from "./components/pages/Projects";
import Home from "./components/pages/Home";

function App() {
  return (
    <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
