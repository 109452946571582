import React from "react";
import useContact from "../controller/useContact";
import { AiFillCheckCircle } from "react-icons/ai";

function Contact() {
  const { fields, handleSubmit, onChangeHandle, errors, isValidForm } =
    useContact();

  return (
    <div className="py-6 flex justify-center sm:py-12">
      {!isValidForm && (
        <div className="relative px-10 py-10 lg:w-1/2 md:w-3/5">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 mb-5">
              Contact me
            </h1>
            <p>Feel free to send me a message!</p>
            <hr className="mt-3 border-gray-300"></hr>
            <div className="divide-y divide-gray-200">
              <form
                className="py-8 text-base leading-6"
                onSubmit={handleSubmit}
              >
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="firstname"
                  placeholder="Name"
                  name="name"
                  value={fields.name}
                  onChange={onChangeHandle}
                ></input>

                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={fields.email}
                  onChange={onChangeHandle}
                ></input>

                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-email"
                >
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  className="resize-y py-3 px-4 border focus:outline-none rounded-md w-full bg-gray-200"
                  placeholder="Your message..."
                  name="message"
                  value={fields.message}
                  onChange={onChangeHandle}
                ></textarea>

                <div className="block text-center font-semibold text-red-500">
                  {errors.name && <p>{errors.name}</p>}
                  {errors.email && <p>{errors.email}</p>}
                  {errors.message && <p>{errors.message}</p>}
                </div>
                <div className="flex items-end justify-center mt-5">
                  <button className="bg-rose-400 text-white font-semibold py-2 px-4 rounded">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isValidForm && (
        <div className="relative px-10 py-10 lg:w-1/2 md:w-3/5">
          <div className="max-w-lg mx-auto">
            <div className="flex justify-center text-8xl font-bold text-rose-400 mb-5">
              <AiFillCheckCircle />{" "}
            </div>
            <h1 className="text-3xl font-bold text-rose-400 mb-5">
              Thank you, I will get back to you shortly!
            </h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
