import React from "react";
import pic from "../images/DSC08442.JPG";

function Home() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-center mx-auto mt-10">
        <div className="lg:hidden w-1/2 shadow-xl rounded-full justify-center relative p-3 bg-rose-300">
          <img className="block rounded-full" src={pic} alt="logo"></img>
        </div>
      </div>
      <div className="flex justify-between mt-10">
        <div className="hidden lg:block w-1/2 shadow-xl rounded-full justify-center relative p-5 bg-rose-300">
          <img className="block rounded-full" src={pic} alt="logo"></img>
        </div>
        <div className="flex flex-col md:ml-20 mx-10 my-auto">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-wide text-rose-400 sm-text-4xl">
            Welcome to my portfolio
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500">
            A{" "}
            <span className="font-semibold text-gray-900">
              fullstack developer
            </span>{" "}
            that is very{" "}
            <span className="font-semibold text-gray-900">passionate</span>{" "}
            about programming and creating aesthetically pleasing designs. I've
            got experience working with PHP, Javascript, Java, C#, C++, ReactJS,
            SQL and looking forward to expand my knowledge further.
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500">
            Based in Melbourne, Australia.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
