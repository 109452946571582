import React, { useState } from "react";
import project1 from "../images/project-images/vibecheck.png";
import project2 from "../images/project-images/wordle.png";
import project3 from "../images/project-images/oshana.png";
import project4 from "../images/project-images/domumgym.png";
import project5 from "../images/project-images/domarahomes.png";

function Projects() {

  const [projects, setProjects] = useState([
    {
      title: "VibeCheck",
      desc: "Social networking app for students, with login/registration and the ability to create post, reply, like/dislike, delete and edit post and customise user profile.",
      img: project1,
      tags: ["ReactJS", "Express.js", "Sequelize", "Bootstrap"],
      link: null,
      source: "https://github.com/stevenballah/vibecheck"
    },
    {
      title: "Wordle Unlimited Clone",
      desc: "A re-creation of the popular game Wordle with a few changes like being able to play unlimited amount of times.",
      img: project2,
      tags: ["ReactJS"],
      link: "https://sb-wordle.netlify.app/",
      source: "https://github.com/stevenballah/wordle-reactjs"
    },
    {
      title: "Oshana Video & Photography",
      desc: "A business website for video and photography with backend database implementation for admin login.",
      img: project3,
      tags: ["PHP", "Bootstrap", "SQL", "Slick.js"],
      link: "https://oshana.com.au/",
      source: null
    },
    {
      title: "DomumGym",
      desc: "A gym platform to share workout videos and access a wide range of fitness media.",
      img: project4,
      tags: ["NextJS", "ReactJS", "Tailwind CSS"],
      link: "https://beta.domumgym.com/",
      source: null
    },
    {
      title: "Domara Homes PTY LTD",
      desc: "A basic business website for a home builder.",
      img: project5,
      tags: ["Wordpress"],
      link: "https://domarahomes.com.au/",
      source: null
    },
  ]);

  return (
    <div className="container mx-auto px-4 py-5 ">
      <h1 className="text-3xl font-bold text-gray-800 mb-5">Projects</h1>
      <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">

        {projects && projects.map((project, index) => {
          return (
            <div className="rounded-lg overflow-hidden shadow-lg shadow-lift">
              <img className="w-full" src={project.img} alt={project.img}></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                  {project.title}
                </div>
                <p class="text-gray-500 text-base">
                  {project.desc}
                </p>
              </div>
              <div className="px-6 py-2">
                {project.link ? (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer underline underline-offset-2 rounded-md px-1 text-sm font-semibold text-rose-400 mr-2 mb-2"
                  >
                    LIVE PREVIEW
                  </a>
                ) : (null)}

                {project.source ? (
                  <a
                    href={project.source}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer underline underline-offset-2 rounded-md px-1 text-sm font-semibold text-rose-400 mr-2 mb-2"
                  >
                    SOURCE CODE
                  </a>
                ) : (null)}

              </div>



              <div className="px-6 py-4">
                {project.tags && project.tags.map((tag, index) => {
                  return (
                    <span className="inline-block bg-rose-400 rounded-full px-3 py-1 text-sm font-semibold text-gray-900 mr-2 mb-2">
                      {tag}
                    </span>
                  );
                })}
              </div>


            </div>
          );
        })}

      </div>
    </div>
  );
}

export default Projects;
