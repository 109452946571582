import { useState, useEffect } from "react";

const useContact = () => {
  const [fields, setFields] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setSubmitted] = useState(false);
    const [isValidForm, setValidForm] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(fields));
    setSubmitted(true);
  };

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  function validate(field) {
    let currentErrors = {};
    if (!field.name.trim()) {
      currentErrors.name = "*Name is required";
    }
    if (!field.email.trim()) {
      currentErrors.email = "*Email is required";
    }
    if (!field.message.trim()) {
      currentErrors.message = "*Message is required";
    }

    return currentErrors;
  }

  useEffect(() => {
     //IF THERE ARE NO ERRORS & SUBMITTED THEN SEND EMAIL
    if (Object.keys(errors).length === 0 && isSubmitted) {
      console.log("EMAIL SENT");
      setValidForm(true);
    }
  }, [isSubmitted, errors]);

  return {
    fields,
    handleSubmit,
    errors,
    isSubmitted,
    onChangeHandle,
    isValidForm
  };
};

export default useContact;
