import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../logo.png";
import logofull from "../../logofull.png";

export default function Navbar() {
  return (
    <nav className="navbar">
      <div className="bg-gray-900">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <img
                className="block md:hidden lg:hidden mr-8 h-8 w-auto m-auto"
                src={logo}
                alt="logo"
              ></img>
              <img
                className="hidden md:block lg:block h-8 w-auto m-auto"
                src={logofull}
                alt="logo"
              ></img>
            </div>

            {/* Menu Items */}
            <div className="flex-shrink-0 flex items-center">
              <div className="sm:block sm:ml-6">
                <div className="flex space-x-6">
                  <NavLink
                    to="/"
                    className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/projects"
                    className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700"
                  >
                    Projects
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700"
                  >
                    Contact
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
